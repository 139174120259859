import { AxiosResponse } from 'axios';
import { RefObject } from 'react';
import { api, post, PostParams } from './Api';
import { LoanBlockingsResponse } from '../../@types/loan-blockings';
import { CompanyBlockingsResponse } from '../../@types/company-blockings';

type LoanBlockingRequest = {
  creditCertificateNumber?: string;
  reason?: string;
};

type GetLoanBlockingParams = LoanBlockingRequest & {
  page?: number;
  size?: number;
};

type CompanyBlockingRequest = {
  mainDocument?: string;
};

type GetCompanyBlockingParams = CompanyBlockingRequest & {
  page?: number;
  size?: number;
};

type ReasonsResponse = {
  value: string;
  text: string;
};

const calculatePageIndex = (page: number): number => (page > 0 ? page - 1 : page);

export const getLoanBlockings = async ({
  creditCertificateNumber,
  reason,
  size,
  page = 0,
}: GetLoanBlockingParams): Promise<AxiosResponse<LoanBlockingsResponse>> => {
  return api.get('/loan-blockings', {
    params: {
      creditCertificateNumber,
      reason,
      size,
      page: calculatePageIndex(page),
    },
  });
};

export const getCompanyBlockings = async ({
  mainDocument,
  size,
  page = 0,
}: GetCompanyBlockingParams): Promise<AxiosResponse<CompanyBlockingsResponse>> => {
  return api.get('/company-blockings', {
    params: {
      mainDocument,
      size,
      page: calculatePageIndex(page),
    },
  });
};

export const createLoanBlocking = async ({
  creditCertificateNumber,
  reason,
}: LoanBlockingRequest): Promise<void> => {
  await api.post('/loan-blockings', { creditCertificateNumber, reason });
};

export const createCompanyBlocking = async ({
  fileRef,
  creditorPlatformId,
}: {
  fileRef: RefObject<File | null>;
  creditorPlatformId: string;
}): Promise<string | void> => {
  if (!fileRef.current) {
    return;
  }

  const body = new FormData();
  body.append('file', fileRef.current);

  const params: PostParams = {
    url: `/company-blockings/${creditorPlatformId}`,
    body,
    headers: {
      Accept: `application/vnd.creditas.v1+json`,
      'Content-Type': 'multipart/form-data',
    },
  };
  await post<string>(params);
};

export const removeLoanBlocking = async ({
  creditCertificateNumber,
  reason,
}: LoanBlockingRequest): Promise<void> => {
  await api.delete('/loan-blockings', { data: { creditCertificateNumber, reason } });
};

export const getReasons = (): ReasonsResponse[] => {
  return [
    {
      value: 'FRAUD',
      text: 'Fraude',
    },
    {
      value: 'CANCELED_CCB',
      text: 'Cancelado manualmente',
    },
    {
      value: 'RENEGOTIATION',
      text: 'Renegociação',
    },
    {
      value: 'CERC_SCD',
      text: 'CERC SCD',
    },
    {
      value: 'CCB_IN_EXECUTION',
      text: 'CCB em execução',
    },
    {
      value: 'FORMALIZATION',
      text: 'Formalização',
    },
    {
      value: 'CREDITAS_AUTO_STORE',
      text: 'Creditas Auto Store',
    },
    {
      value: 'CONTRACT_CANCELLATION',
      text: 'Contrato cancelado automaticamente',
    },
    {
      value: 'CUSTOMER_WITHDRAWAL',
      text: 'Desistência por parte do cliente',
    },
    {
      value: 'CONTRACT_INCONSISTENCY',
      text: 'Inconsistência nas condições do contrato',
    },
    {
      value: 'CONTRACT_UPDATE',
      text: 'Reestruturação por alteração contratual',
    },
    {
      value: 'CONTRACT_EXTENSION_RENEGOTIATED',
      text: 'Reestruturação por extensão',
    },
    {
      value: 'CONTRACT_DUE_DATE_RENEGOTIATED',
      text: 'Reestruturação por alteração de data de vencimento',
    },
    {
      value: 'CONTRACT_DUE_DATE_PRESERVING_VALUE_RENEGOTIATED',
      text: 'Reestruturação por alteração de data de vencimento preservando valor',
    },
    {
      value: 'POSTPONEMENT',
      text: 'Reestruturação por postergação',
    },
    {
      value: 'CONTRACT_FLEXIBILIZATION_RENEGOTIATED',
      text: 'Reestruturação por flexibilização de parcelas',
    },
    {
      value: 'CONTRACT_INTEREST_RATE_RENEGOTIATED',
      text: 'Reestruturação por alteração de taxa',
    },
    {
      value: 'PARTIAL_AMORTIZATION',
      text: 'Reestruturação por amortização parcial',
    },
    {
      value: 'LONG_OVERDUE',
      text: 'Reestruturação 120+',
    },
    {
      value: 'RENEGOTIATION_INTENT',
      text: 'Intenção de Reestruturação',
    },
    {
      value: 'LEGAL_ACTION',
      text: 'Contrato em processo judicial',
    },
    {
      value: 'WARRANTY_CLAIM_PROCESSING',
      text: 'Garantia em processo de retomada',
    },
    {
      value: 'CONTRACT_TERMINATION',
      text: 'Distrato',
    },
    {
      value: 'UNKNOWN',
      text: 'Motivo desconhecido',
    },
    {
      value: 'SERASA_FAIR',
      text: 'Contrato no Feirão Limpa Nome - Serasa',
    },
    {
      value: 'IQ',
      text: 'Interveniente Quitante - IQ',
    },
    {
      value: 'TICKET',
      text: 'Contrato modificado por ticket em servicing',
    },
  ];
};
