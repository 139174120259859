import React from 'react';

import { TableRow } from '@creditas/table';
import times from 'lodash.times';
import { useTranslation } from '@creditas/i18n';
import { TableCell } from './LoanBlockingsTable.style';
import { LoanBlockingsRemoveButton } from '../loan-blockings-remove-button';
import { LoanBlockingsResponse } from '../../../../../@types/loan-blockings';
import LoadableContent from '../../../../../modules/LoadableContent';

interface LoanBlockingsTableProps {
  data?: LoanBlockingsResponse;
  loading: boolean;
  pageSize?: number;
}

export const LoanBlockingsTableData: React.FC<LoanBlockingsTableProps> = ({
  data,
  loading,
  pageSize = 10,
}) => {
  const { t } = useTranslation();
  const enableRemoveButton = (reason: string): boolean => {
    const reasonsNotRemoved = ['SERASA_FAIR', 'FRAUD'];
    return !reasonsNotRemoved.includes(reason);
  };

  return (
    <>
      {loading
        ? times(pageSize, (index: number) => (
            <TableRow data-testid="skeleton-table-row" key={index}>
              <TableCell>
                <LoadableContent
                  loading={loading}
                  isRelativeWidth
                  minWidth={50}
                  topSpacing={9}
                  bottomSpacing={9}
                />
              </TableCell>
              <TableCell>
                <LoadableContent
                  loading={loading}
                  isRelativeWidth
                  minWidth={50}
                  topSpacing={9}
                  bottomSpacing={9}
                />
              </TableCell>
              <TableCell>
                <LoadableContent
                  loading={loading}
                  isRelativeWidth
                  minWidth={50}
                  topSpacing={9}
                  bottomSpacing={9}
                />
              </TableCell>
            </TableRow>
          ))
        : (data as LoanBlockingsResponse).content.map(loanBlocking => (
            <TableRow data-testid="table-row" key={loanBlocking.id}>
              <TableCell>{loanBlocking.creditCertificateNumber}</TableCell>
              <TableCell>{t(`blockingReason.${loanBlocking.reason}`)}</TableCell>
              <TableCell>
                {enableRemoveButton(loanBlocking.reason) && (
                  <LoanBlockingsRemoveButton
                    creditCertificateNumber={loanBlocking.creditCertificateNumber}
                    reason={loanBlocking.reason}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
    </>
  );
};
