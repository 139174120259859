import { styled } from '@creditas/stylitas';
import { Theme } from '../../interfaces/theme';

type StyleProps = { isOpen?: boolean; width?: string };

export const ModalContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const ModalBlind = styled.div`
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  z-index: 2;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
`;

export const ModalBox = styled.div<StyleProps, Theme>`
  width: ${(props): string => (props.width ? props.width : '640px')};
  min-width: 300px;
  max-width: calc(100% - 20px);
  min-height: 200px;
  max-height: calc(100% - 20px);
  background-color: ${({ theme }): string => theme.bColorneutral01};
  box-shadow: 0 1px 2px 0 ${({ theme }): string => theme.bColorneutral03},
    0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  z-index: 3;
  position: relative;
  transition: 0.4s;
  transform: translate(0, -100%);
  opacity: 0;
  ${(props): boolean | string | undefined =>
    props.isOpen &&
    `
      transform: translate(0,0);
      opacity: 1;
    `}
`;

export const IconWrapper = styled.button<StyleProps, Theme>`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  border: none;
  border-radius: 6px;
  background: none;
  &:hover {
    background: ${({ theme }): string => theme.bColorneutral02};
  }
`;
