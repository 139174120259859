interface GridMedia {
  xs: string;
  md: string;
}

export const gridMedia = (xs: string, md: string): GridMedia => {
  return {
    xs,
    md,
  };
};
