import React, { useContext } from 'react';
import { Action } from '@creditas/icons';
import { LoanBlockingPageContext } from '../../../../../contexts';

interface LoanBlockingsRemoveButtonProps {
  creditCertificateNumber: string;
  reason: string;
}

export const LoanBlockingsRemoveButton: React.FC<LoanBlockingsRemoveButtonProps> = ({
  creditCertificateNumber,
  reason,
}) => {
  const { setLoanBlockingRequest, setShowRemoveModal } = useContext(LoanBlockingPageContext);

  return (
    <>
      <Action.Trash2
        data-testid="remove-loan-blocking"
        fontSize="default"
        color="grey"
        onClick={(): void => {
          setLoanBlockingRequest({ creditCertificateNumber, reason });
          setShowRemoveModal(true);
        }}
      />
    </>
  );
};
