import { ActionLink as CreditasActionLink } from '@creditas/button';
import { styled } from '@creditas/stylitas';
import { tokens } from '@creditas/tokens';
import { Theme } from '../../../../../interfaces/theme';

export const ActionLink = styled(CreditasActionLink)<{ disabled: boolean }, typeof tokens>`
  padding: 0;
  svg {
    fill: ${({ disabled, theme }: Theme): string =>
      disabled ? theme.bColorneutral04 : theme.bColorsecondary04};
  }
  &:hover svg {
    ${({ disabled, theme }: Theme): string | false =>
      !disabled && `fill: ${theme.bColorsecondary06}`};
  }
`;
