import { Button } from '@creditas/button';
import { Typography } from '@creditas/typography';
import React from 'react';

import { useTranslation } from '@creditas/i18n';
import { ConfimationContainer } from './LoanBlockingsRemoveValidationModal.style';
import { Modal } from '../../../../../modules/Modal';

interface LoanBlockingsRemoveValidationModalProps {
  question: string;
  removeLoanBlocking: ({
    creditCertificateNumber,
    reason,
  }: {
    creditCertificateNumber: string;
    reason: string;
  }) => Promise<void>;
  creditCertificateNumber: string;
  reason: string;
  showRemoveModal: boolean;
  setShowRemoveModal: (value: boolean) => void;
}

export const LoanBlockingsRemoveValidationModal: React.FC<LoanBlockingsRemoveValidationModalProps> = ({
  removeLoanBlocking,
  question,
  creditCertificateNumber,
  reason,
  showRemoveModal,
  setShowRemoveModal,
}) => {
  const { t } = useTranslation();

  return (
    <Modal canShow={showRemoveModal} hide={(): void => setShowRemoveModal(false)}>
      <ConfimationContainer>
        <Typography>{question}</Typography>
      </ConfimationContainer>
      <ConfimationContainer>
        <Button data-testid="deny-action" secondary onClick={(): void => setShowRemoveModal(false)}>
          {t('no')}
        </Button>
        <Button
          data-testid="accept-action"
          onClick={(): void => {
            removeLoanBlocking({
              creditCertificateNumber,
              reason,
            });
            setShowRemoveModal(false);
          }}
        >
          {t('yes')}
        </Button>
      </ConfimationContainer>
    </Modal>
  );
};
