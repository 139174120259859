import { InputSelect } from '@creditas/form-elements';
import { styled, utilities } from '@creditas/stylitas';
import { Typography } from '@creditas/typography';
import { gridMedia } from '../../../../../utils/grid-utils';

export const gridFiltersTitle = {
  gridColumn: gridMedia('1', '1'),
  gridRow: gridMedia('3', '3'),
};

export const gridContractFilter = {
  gridColumn: gridMedia('1 / 4', '1 / 4'),
  gridRow: gridMedia('4', '4'),
};

export const gridReasonFilter = {
  gridColumn: gridMedia('1 / 4', '4 / 7'),
  gridRow: gridMedia('5', '4'),
};

export const gridResetFilters = {
  gridColumn: gridMedia('12 / 12', '7 / 10'),
  gridRow: gridMedia('4', '4'),
};

export const LoanBlockingsFiltersTitle = styled(Typography)`
  color: gray;
  margin-top: 50px;
  margin-bottom: -5px;
  ${utilities.typography.weight.semiBold};
`;

export const LoanBlockingsFilterSelect = styled(InputSelect)`
  select {
    text-overflow: ellipsis;
    width: 100%;
  }
`;
