import { Button } from '@creditas/button';
import { styled } from '@creditas/stylitas';
import { Typography } from '@creditas/typography';
import { Grid } from '@creditas/layout';
import { gridMedia } from '../../../../../utils/grid-utils';

export const StyledButton = styled(Button)`
  text-align: center;
  font-size: 17px;
  span {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  min-width: ${(props): string => (props.minWidth ? props.minWidth : '180px')};
  height: 40px;
  padding: 0;
`;

export const ModalTitle = styled(Typography)`
  /* margin-bottom: 30px; */
`;

export const AddCompanyBlockingForm = styled(Grid)`
  margin: 20px 30px 50px 70px;
  grid-gap: 20px; // espaçamento entre os elementos
`;

export const gridItemModalTitle = {
  gridColumn: gridMedia('1 / -1', '1 / -1'),
  gridRow: gridMedia('1', '1'),
};
export const gridBlockingCreditors = {
  gridColumn: gridMedia('1 / -1', '1 / -1'),
  gridRow: gridMedia('2', '2'),
};
export const gridUploadBox = {
  gridColumn: gridMedia('1 / -1', '1 / -1'),
  gridRow: gridMedia('3', '3'),
};
export const gridCancelButton = {
  gridColumn: gridMedia('1 / 2', '1 / 2'),
  gridRow: gridMedia('4', '4'),
};
export const gridConfirmButton = {
  gridColumn: gridMedia('3 / 4', '4 / 4'),
  gridRow: gridMedia('4', '4'),
};

export const gridContainerOptions = {
  gridJustifyContent: 'center',
  gridColumns: {
    xs: 'repeat(4, 1fr)',
    md: 'repeat(4, 1fr)',
  },
};
