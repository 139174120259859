import React, { ReactNode, useMemo } from 'react';

import Skeleton from 'react-loading-skeleton';

import getRandomNumber from '../../utils/get-random-number';
import { SkeletonWrapper } from './LoadableContent.style';

type LoadableContentProps = {
  children?: ReactNode;
  count?: number;
  height?: number;
  loading?: boolean;
  width?: number;
  fullWidth?: boolean;
  isRelativeWidth?: boolean;
  topSpacing?: number;
  bottomSpacing?: number;
  leftSpacing?: number;
  rightSpacing?: number;
  maxWidth?: number;
  minWidth?: number;
};

const LoadableContent: React.FC<LoadableContentProps> = ({
  children,
  count,
  height,
  loading,
  width,
  fullWidth,
  isRelativeWidth,
  topSpacing = 0,
  bottomSpacing = 0,
  leftSpacing = 0,
  rightSpacing = 0,
  minWidth = 50,
  maxWidth = 200,
}) => {
  const skeletonWidth = useMemo(() => {
    if (fullWidth || isRelativeWidth) {
      return undefined;
    }
    return (
      width ||
      getRandomNumber({
        min: minWidth,
        max: maxWidth,
      })
    );
  }, [width, fullWidth, minWidth, maxWidth, isRelativeWidth]);

  const skeletonWrapperWidthPercentage = useMemo(() => {
    if (!isRelativeWidth) {
      return 100;
    }
    return getRandomNumber({ min: minWidth, max: Math.min(maxWidth, 100) });
  }, [minWidth, maxWidth, isRelativeWidth]);

  return loading ? (
    <SkeletonWrapper
      topSpacing={topSpacing}
      bottomSpacing={bottomSpacing}
      leftSpacing={leftSpacing}
      rightSpacing={rightSpacing}
      widthPercentage={skeletonWrapperWidthPercentage}
    >
      <Skeleton height={height} count={count} width={skeletonWidth} />
    </SkeletonWrapper>
  ) : (
    <>{children}</>
  );
};

export default LoadableContent;
