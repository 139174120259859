import React, { ChangeEvent, useState } from 'react';
import { GridItem } from '@creditas/layout';
import { InputField, InputSelect } from '@creditas/form-elements';
import { useTranslation } from '@creditas/i18n';
import { Modal } from '../../../../../modules/Modal';
import {
  AddLoanBlockingForm,
  gridContainerOptions,
  StyledButton,
  ModalTitle,
  gridItemModalTitle,
  gridLoanInput,
  gridBlockingReasons,
  gridCancelButton,
  gridConfirmButton,
} from './LoanBlockingsAddModal.style';
import { getReasons } from '../../../../../services/funding-eligibility/FundingEligibility';

interface LoanBlockingsAddButtonProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  createLoanBlocking: ({
    creditCertificateNumber,
    reason,
  }: {
    creditCertificateNumber: string;
    reason: string;
  }) => Promise<void>;
}

const options = getReasons();

export const LoanBlockingsAddModal: React.FC<LoanBlockingsAddButtonProps> = ({
  showModal,
  setShowModal,
  createLoanBlocking,
}) => {
  const [creditCertificateNumber, setCreditCertificateNumber] = useState('');
  const [reason, setReason] = useState('');
  const { t } = useTranslation();

  return (
    <Modal canShow={showModal} hide={(): void => setShowModal(false)} width="700px">
      <AddLoanBlockingForm options={gridContainerOptions}>
        <GridItem gridColumn={gridItemModalTitle.gridColumn} gridRow={gridItemModalTitle.gridRow}>
          <ModalTitle>{t('loanBlocking.addNew')}</ModalTitle>
        </GridItem>
        <GridItem gridColumn={gridLoanInput.gridColumn} gridRow={gridLoanInput.gridRow}>
          <InputField
            label={t('contract')}
            size="default"
            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
              setCreditCertificateNumber(e.target.value);
            }}
          />
        </GridItem>
        <GridItem gridColumn={gridBlockingReasons.gridColumn} gridRow={gridBlockingReasons.gridRow}>
          <InputSelect
            label={t('loanBlocking.reason')}
            size="default"
            options={options}
            onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
              setReason(e.target.value);
            }}
          />
        </GridItem>
        <GridItem gridColumn={gridCancelButton.gridColumn} gridRow={gridCancelButton.gridRow}>
          <StyledButton
            data-testid="deny-action"
            minWidth="127px"
            variant="secondary"
            onClick={(): void => setShowModal(false)}
          >
            {t('cancel')}
          </StyledButton>
        </GridItem>
        <GridItem gridColumn={gridConfirmButton.gridColumn} gridRow={gridConfirmButton.gridRow}>
          <StyledButton
            minWidth="127px"
            data-testid="accept-action"
            onClick={(): void => {
              createLoanBlocking({
                creditCertificateNumber,
                reason,
              });
              setShowModal(false);
            }}
          >
            {t('add')}
          </StyledButton>
        </GridItem>
      </AddLoanBlockingForm>
    </Modal>
  );
};
