import axios, { AxiosInstance, AxiosRequestHeaders, ResponseType } from 'axios';
import { requestInterceptor } from '../Interceptors';
import { envVars } from '../../envVars';

export const api: AxiosInstance = axios.create({
  baseURL: envVars.FUNDING_ELIGIBILITY_APP_URL,
});

type BaseParams = {
  url: string;
  headers: AxiosRequestHeaders;
};

export type PostParams = BaseParams & {
  body: Record<string, unknown> | FormData;
  responseType?: ResponseType;
};

export async function post<T>({ url, body, headers, responseType }: PostParams): Promise<T> {
  const { data } = await api.post<T>(url, body, { headers, responseType });
  return data;
}

api.interceptors.request.use(requestInterceptor);
