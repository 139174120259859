import singleSpaReact from 'single-spa-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './App';
import { i18nInit } from './i18n';
import { envVars } from './envVars';

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  domElementGetter: (): Element => document.getElementById(envVars.APP_NAME) as Element,
});

export const bootstrap = [
  reactLifecycles.bootstrap,
  async (): Promise<void> => {
    await i18nInit;
    return Promise.resolve();
  },
];

export const mount = [reactLifecycles.mount];

export const unmount = [reactLifecycles.unmount];
