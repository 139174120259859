import { styled } from '@creditas/stylitas';

type SkeletonWrapperProps = {
  topSpacing: number;
  bottomSpacing: number;
  leftSpacing: number;
  rightSpacing: number;
  widthPercentage: number;
};

export const SkeletonWrapper = styled.div<SkeletonWrapperProps>`
  ${({ topSpacing, bottomSpacing, rightSpacing, leftSpacing, widthPercentage }): string => `
    padding: ${topSpacing}px ${rightSpacing}px ${bottomSpacing}px ${leftSpacing}px;
    width: ${widthPercentage}%;
  `}
`;
