import React, { useContext, Suspense, FC } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Blockings } from './pages/Blockings/Blockings';

import { CustomPropsContext } from './contexts/CustomPropsContext';

export const Routes: FC = () => {
  const { basePath } = useContext(CustomPropsContext);

  return (
    <BrowserRouter>
      <Suspense fallback={null}>
        <Switch>
          <Route path={`${basePath}/bloqueios-de-contrato`} exact component={Blockings} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
