import { AxiosRequestConfig } from 'axios';
import { getAccessToken } from './auth/auth';

export const requestInterceptor = (request: AxiosRequestConfig): AxiosRequestConfig => {
  const accessToken = getAccessToken();

  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
    request.headers['Content-Type'] = 'application/json';
  }
  return request;
};
