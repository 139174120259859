import { createContext } from 'react';

type LoanBlockingPageProps = {
  setLoanBlockingRequest: ({
    creditCertificateNumber,
    reason,
  }: {
    creditCertificateNumber: string;
    reason: string;
  }) => void;
  setShowRemoveModal: (value: boolean) => void;
};

export const LoanBlockingPageContext = createContext<LoanBlockingPageProps>({
  setLoanBlockingRequest: () => {},
  setShowRemoveModal: () => {},
});

export const LoanBlockingPageProvider = LoanBlockingPageContext.Provider;
