import React, { useState } from 'react';
import { useTranslation } from '@creditas/i18n';
// eslint-disable-next-line import/no-extraneous-dependencies
import { TabItem, Tabs } from '@creditas/tabs';
import { GridItem } from '@creditas/layout';
import { PageTitle } from './components/PageTitle/PageTitle';
import { gridMedia } from '../../utils/grid-utils';
import { LoanBlockings } from './LoanBlockings/LoanBlockings';
import { gridContainerOptions, PageWrapper } from './LoanBlockings/LoanBlockings.style';
import { CompanyBlockings } from './CompanyBlockings';

export const Blockings: React.FC = () => {
  const { t } = useTranslation();

  const [tabBlockedLoans, setTabBlockedLoans] = useState<string>('selected');
  const [tabBlockedCompanies, setBlockedCompanies] = useState<string>('');

  function clickedTab(tab: string): void {
    if (tab === 'loans') {
      setBlockedCompanies('');
      setTabBlockedLoans('selected');
    } else if (tab === 'companies') {
      setBlockedCompanies('selected');
      setTabBlockedLoans('');
    }
  }

  return (
    <>
      <PageWrapper options={gridContainerOptions}>
        <PageTitle title={t('pages.loanBlocking.title')} />
        <GridItem gridColumn={gridMedia('1 / 12', '1 / 12')} gridRow={gridMedia('2', '2')}>
          <Tabs>
            <TabItem onClick={(): void => clickedTab('loans')} variant={tabBlockedLoans}>
              {t('loansBlocked')}
            </TabItem>
            <TabItem onClick={(): void => clickedTab('companies')} variant={tabBlockedCompanies}>
              {t('companiesBlocked')}
            </TabItem>
          </Tabs>
        </GridItem>
        {tabBlockedLoans !== '' ? <LoanBlockings /> : <CompanyBlockings />}
      </PageWrapper>
    </>
  );
};
