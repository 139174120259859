import React, { ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { Action } from '@creditas/icons';

import { ModalContainer, ModalBlind, ModalBox, IconWrapper } from './Modal.style';

interface ModalProps {
  canShow: boolean;
  children: ReactNode;
  width?: string;
  hide: () => void;
}

export const Modal: React.FC<ModalProps> = ({ canShow, hide, children, width }: ModalProps) => {
  useEffect(() => {
    const keyHandler = (e: KeyboardEvent): void => {
      if (e.key === 'Escape') {
        hide();
      }
    };
    if (canShow) {
      document.addEventListener('keydown', keyHandler);
    }
    return (): void => {
      document.removeEventListener('keydown', keyHandler);
    };
  }, [canShow, hide]);

  return canShow
    ? ReactDOM.createPortal(
        <ModalContainer>
          <ModalBlind data-testid="blind-modal" onClick={hide} />
          <ModalBox isOpen={canShow} width={width}>
            <IconWrapper data-testid="close-modal" onClick={hide}>
              <Action.IconSystemClose fontSize="large" color="neutral07" />
            </IconWrapper>
            {children}
          </ModalBox>
        </ModalContainer>,
        document.body,
      )
    : null;
};
