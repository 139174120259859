import React from 'react';
import { TableRow } from '@creditas/table';
import { GridItem } from '@creditas/layout';
import { PaginationTable } from '@creditas/pagination';

import { useTranslation } from '@creditas/i18n';
import {
  StyledCreditasTable,
  TableCell,
  TableHead,
  TableBody,
  gridTable,
  gridPaginationTable,
  NoRecordsTableCell,
  NoRecordsTableMessage,
} from './CompanyBlockingsTable.style';
import { CompanyBlockingsTableData } from './CompanyBlockingsTableData';
import { SearchIcon } from '../../../../../modules/icons/SearchIcon';
import { CompanyBlockingsResponse } from '../../../../../@types/company-blockings';

type updateType = 'replace' | 'replaceIn' | 'push' | 'pushIn' | undefined;

interface CompanyBlockingsTableProps {
  data?: CompanyBlockingsResponse;
  loading: boolean;
  headers: string[];
  page: number;
  pageSize: number;
  setPage: (page: number, updateType?: updateType) => void;
  setPageSize: (pageSize: number, updateType?: updateType) => void;
}

export const CompanyBlockingsTable: React.FC<CompanyBlockingsTableProps> = ({
  data,
  loading,
  headers,
  page,
  pageSize,
  setPage,
  setPageSize,
}) => {
  const { t } = useTranslation();
  const noResults = data?.content && data.content.length === 0;

  return (
    <>
      <GridItem gridColumn={gridTable.gridColumn} gridRow={gridTable.gridRow}>
        <StyledCreditasTable fullWidth>
          <TableHead>
            <TableRow>
              {headers.map(headerTitle => (
                <TableCell textAlign="left" key={headerTitle}>
                  {headerTitle}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody variant="striped" stripedColorOdd="#e0e0e0">
            {noResults && !loading ? (
              <TableRow height={545}>
                <NoRecordsTableCell colSpan={7}>
                  <SearchIcon height={96} />
                  <NoRecordsTableMessage variant="h4">
                    {t('companyBlockingsTable.noResults')}
                  </NoRecordsTableMessage>
                </NoRecordsTableCell>
              </TableRow>
            ) : (
              <CompanyBlockingsTableData data={data} loading={loading} pageSize={pageSize} />
            )}
          </TableBody>
        </StyledCreditasTable>
      </GridItem>
      <GridItem gridColumn={gridPaginationTable.gridColumn} gridRow={gridPaginationTable.gridRow}>
        <PaginationTable
          current={page}
          pageSize={pageSize}
          total={data?.totalPages}
          onChangePage={(newPage: number): void => setPage(newPage, 'pushIn')}
          onChangePageSize={(newPageSize: number): void => {
            setPageSize(newPageSize, 'pushIn');
            setPage(1, 'replaceIn');
          }}
        />
      </GridItem>
    </>
  );
};
