import React from 'react';

import { TableRow } from '@creditas/table';
import times from 'lodash.times';
import { TableCell } from './CompanyBlockingsTable.style';
import LoadableContent from '../../../../../modules/LoadableContent';
import { CompanyBlockingsResponse } from '../../../../../@types/company-blockings';

interface CompanyBlockingsTableProps {
  data?: CompanyBlockingsResponse;
  loading: boolean;
  pageSize?: number;
}

export const CompanyBlockingsTableData: React.FC<CompanyBlockingsTableProps> = ({
  data,
  loading,
  pageSize = 30,
}) => {
  return (
    <>
      {loading
        ? times(pageSize, (index: number) => (
            <TableRow data-testid="skeleton-table-row" key={index}>
              <TableCell>
                <LoadableContent
                  loading={loading}
                  isRelativeWidth
                  minWidth={50}
                  topSpacing={9}
                  bottomSpacing={9}
                />
              </TableCell>
              <TableCell>
                <LoadableContent
                  loading={loading}
                  isRelativeWidth
                  minWidth={50}
                  topSpacing={9}
                  bottomSpacing={9}
                />
              </TableCell>
              <TableCell>
                <LoadableContent
                  loading={loading}
                  isRelativeWidth
                  minWidth={50}
                  topSpacing={9}
                  bottomSpacing={9}
                />
              </TableCell>
            </TableRow>
          ))
        : (data as CompanyBlockingsResponse).content.map(companyBlocking => (
            <TableRow data-testid="table-row" key={companyBlocking.id}>
              <TableCell>{companyBlocking.name}</TableCell>
              <TableCell>{companyBlocking.mainDocument}</TableCell>
              <TableCell>{companyBlocking.creditorNickname}</TableCell>
            </TableRow>
          ))}
    </>
  );
};
