import { styled } from '@creditas/stylitas';
import { Grid } from '@creditas/layout';

export const PageWrapper = styled(Grid)`
  margin: 60px;
`;

export const gridContainerOptions = {
  gridJustifyContent: 'center',
  gridColumns: {
    xs: 'repeat(11, 1fr)',
    md: 'repeat(11, 1fr)',
  },
  gridRowGap: {
    xs: '10px',
    md: '10px',
  },
};
