import { Button } from '@creditas/button';
import { styled } from '@creditas/stylitas';
import { Typography } from '@creditas/typography';
import { Grid } from '@creditas/layout';
import { gridMedia } from '../../../../../utils/grid-utils';

export const StyledButton = styled(Button)`
  margin-top: -10px;
  text-align: center;
  font-size: 16px;
  span {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  min-width ${(props): string => (props.minWidth ? props.minWidth : '180px')};
  width: 230px;
  height: 40px;
  padding: 10px 30px;
`;

export const ModalTitle = styled(Typography)`
  margin-bottom: 30px;
`;

export const AddCompanyBlockingForm = styled(Grid)`
  margin: 20px 30px 50px 70px;
`;

export const gridAddLoanBlockingsButton = {
  gridColumn: gridMedia('11/12', '11/12'),
  gridRow: gridMedia('5', '5'),
};

export const gridContainerOptions = {
  gridJustifyContent: 'center',
  gridColumns: {
    xs: 'repeat(4, auto-fit, 1fr)',
    md: 'repeat(4 , auto-fit, 1fr)',
  },

  gridRowGap: {
    xs: '0px',
    md: '0px',
  },
};
