import React from 'react';
import { GridItem } from '@creditas/layout';
import { Content } from '@creditas/icons';
import { StyledButton, gridAddLoanBlockingsButton } from './AddLoanBlockingButton.style';

interface LoanBlockingsAddButtonProps {
  label: string;
  setShowModal: (value: boolean) => void;
}

export const LoanBlockingsAddButton: React.FC<LoanBlockingsAddButtonProps> = ({
  label,
  setShowModal,
}) => {
  return (
    <GridItem
      gridColumn={gridAddLoanBlockingsButton.gridColumn}
      gridRow={gridAddLoanBlockingsButton.gridRow}
    >
      <StyledButton
        endIcon={<Content.Plus fontSize="small" />}
        onClick={(): void => setShowModal(true)}
      >
        {label}
      </StyledButton>
    </GridItem>
  );
};
