import React from 'react';

import { Action } from '@creditas/icons';
import { GridItem } from '@creditas/layout';
import { useTranslation } from '@creditas/i18n';
import { InputField } from '@creditas/form-elements';
import {
  LoanBlockingsFiltersTitle,
  gridFiltersTitle,
  gridResetFilters,
  gridMainDocumentFilter,
} from './CompanyBlockingsFilters.style';
import LoadableContent from '../../../../../modules/LoadableContent';
import { ActionLink } from '../../../components/PageTitle/action-link/ActionLink.style';

export type FilterValues = {
  mainDocument?: string;
};

export type FiltersProps = {
  values: FilterValues;
  resetButtonDisabled: boolean;
  onChange: (values: FilterValues) => void;
  onReset: () => void;
  loading?: boolean;
};

const inputBackground = '#f8f8f8';

export const CompanyBlockingsFilters: React.FC<FiltersProps> = ({
  values,
  resetButtonDisabled,
  loading,
  onChange,
  onReset,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <GridItem gridColumn={gridFiltersTitle.gridColumn} gridRow={gridFiltersTitle.gridRow}>
        <LoanBlockingsFiltersTitle variant="textParagraph">
          {t('filters.title')}
        </LoanBlockingsFiltersTitle>
      </GridItem>
      <GridItem
        gridColumn={gridMainDocumentFilter.gridColumn}
        gridRow={gridMainDocumentFilter.gridRow}
      >
        <LoadableContent loading={loading} fullWidth height={40} bottomSpacing={16}>
          <InputField
            data-testid="company-blockings-filter"
            label={t('filters.company.title')}
            onChange={(item): void => onChange({ ...values, mainDocument: item.target.value })}
            size="comfortable"
            value={values.mainDocument ? values.mainDocument : ''}
            noItemsText={t('filters.assignee.noItems')}
            inputBackground={inputBackground}
            width=""
          />
        </LoadableContent>
      </GridItem>
      <GridItem gridColumn={gridResetFilters.gridColumn} gridRow={gridResetFilters.gridRow}>
        <LoadableContent loading={loading} bottomSpacing={32} height={24} width={200}>
          <ActionLink
            onClick={resetButtonDisabled ? undefined : onReset}
            disabled={resetButtonDisabled}
          >
            <Action.XCircle />
            {t('filters.cleanAll')}
          </ActionLink>
        </LoadableContent>
      </GridItem>
    </>
  );
};
