export const envVars = {
  APP_NAME: 'funding-eligibility',
  PUBLIC_URL: process.env.PUBLIC_URL || '',
  REACT_APP_ENV: process.env.REACT_APP_ENV || '',
  PORT: process.env.PORT || '',
  SKIP_PREFLIGHT_CHECK: process.env.SKIP_PREFLIGHT_CHECK || '',
  FUNDING_ELIGIBILITY_APP_URL: process.env.FUNDING_ELIGIBILITY_APP_URL || '',
  FLAGSMITH_ENVIRONMENT_ID: process.env.FLAGSMITH_ENVIRONMENT_ID || '',
  FUND_URL: process.env.FUND_URL || '',
};
