import { useAsync, useAsyncCallback } from 'react-async-hook';
import { useTranslation } from '@creditas/i18n';
import { useQueryParams, withDefault, NumberParam, StringParam } from 'use-query-params';
import React, { useState } from 'react';

import { useToaster } from '@creditas/toaster';
import {
  createCompanyBlocking,
  getCompanyBlockings,
} from '../../../services/funding-eligibility/FundingEligibility';
import { CompanyBlockingsTable } from './components/company-blockings-table';
import { CompanyBlockingPageProvider } from '../../../contexts/CompanyBlockingPageContext';
import { CompanyBlockingsAddButton } from './components/company-blockings-add-button';
import { CompanyBlockingsAddModal } from './components/company-blockings-add-modal/CompanyBlockingsAddModal';
import { CompanyBlockingsFilters } from './components/company-blockings-filters';
import { FilterValues } from './components/company-blockings-filters/CompanyBlockingsFilters';

export const CompanyBlockings: React.FC = () => {
  const { t } = useTranslation();
  const { push } = useToaster();
  const headerTitles = [
    t('companyBlockingsTable.header.name'),
    t('companyBlockingsTable.header.mainDocument'),
    t('companyBlockingsTable.header.blockedBy'),
  ];

  const defaultFilterParams = {
    mainDocument: undefined,
  };

  const [query, setQuery] = useQueryParams({
    mainDocument: withDefault(StringParam, defaultFilterParams.mainDocument),
    page: withDefault(NumberParam, 1),
    pageSize: withDefault(NumberParam, 30),
  });

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const isChangedStateFiltersQuery = Object.entries(defaultFilterParams).some(
    ([key, value]): boolean => {
      return (query as any)[key] !== value;
    },
  );

  const filterValues = {
    ...query,
  };

  const onFiltersChange = (values: FilterValues): void => {
    setQuery({ ...values, page: 1, mainDocument: values.mainDocument });
  };

  const onReset = (): void => {
    setQuery({ pageSize: query.pageSize }, 'push');
  };

  const { result: companyBlockings, loading, execute } = useAsync(
    () =>
      getCompanyBlockings({
        mainDocument: query.mainDocument,
        page: query.page,
        size: query.pageSize,
      }),
    [query],
    {
      setLoading: state => ({ ...state, loading: true }),
    },
  );

  const [showAddCompanyBlockingModal, setShowAddCompanyBlockingModal] = useState(false);

  const createCompanyBlockingCallback = useAsyncCallback(createCompanyBlocking, {
    onSuccess: () => {
      execute();
      push(t('companyBlocking.create.feedbackMessage.success'), { level: 'success' });
    },
    onError: e =>
      e.message === 'Request failed with status code 422'
        ? push(t('companyBlocking.create.feedbackMessage.verifyFile'), { level: 'danger' })
        : push(t('companyBlocking.create.feedbackMessage.error'), { level: 'danger' }),
  });

  return (
    <CompanyBlockingPageProvider value={{}}>
      <CompanyBlockingsFilters
        values={filterValues}
        onChange={onFiltersChange}
        onReset={onReset}
        resetButtonDisabled={!isChangedStateFiltersQuery}
        loading={false}
      />
      <CompanyBlockingsTable
        headers={headerTitles}
        data={companyBlockings?.data}
        loading={loading}
        page={query.page}
        pageSize={query.pageSize}
        setPage={(page: number): void => setQuery({ page })}
        setPageSize={(pageSize: number): void => setQuery({ pageSize, page: 1 })}
      />
      <CompanyBlockingsAddButton
        label={t('companyBlocking.addNew')}
        setShowModal={setShowAddCompanyBlockingModal}
      />
      <CompanyBlockingsAddModal
        showModal={showAddCompanyBlockingModal}
        setShowModal={setShowAddCompanyBlockingModal}
        createCompanyBlocking={createCompanyBlockingCallback.execute}
      />
    </CompanyBlockingPageProvider>
  );
};
