import React from 'react';

type SearchIconProps = {
  width?: number;
  height?: number;
};

export const SearchIcon: React.FC<SearchIconProps> = ({ width, height }) => (
  <svg viewBox="0 0 144 144" width={width} height={height}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M55.238 95.284c-18.422.036-34.477-12.537-38.857-30.43a2.288 2.288 0 114.442-1.098C25.092 81.168 41.692 92.648 59.489 90.5c17.798-2.15 31.187-17.254 31.187-35.18 0-17.928-13.389-33.031-31.187-35.181-17.797-2.15-34.397 9.33-38.666 26.742a2.286 2.286 0 01-4.442-1.074c4.935-20.278 24.625-33.357 45.23-30.046 20.605 3.312 35.205 21.903 33.538 42.705-1.667 20.802-19.042 36.83-39.91 36.819z"
        fill="#285"
      />
      <path
        d="M55.246 109.798c-26.002.013-48.396-18.336-53.497-43.833-5.1-25.497 8.511-51.048 32.517-61.04 24.006-9.991 51.727-1.643 66.223 19.944 14.496 21.587 11.733 50.405-6.6 68.845l-.335.32 5.189 5.189a2.287 2.287 0 11-3.23 3.238l-6.858-6.857a2.286 2.286 0 010-3.23l.869-.846 1.105-1.082c18.283-18.449 19.343-47.842 2.437-67.56C76.16 3.168 46.95-.272 25.926 14.98 4.903 30.232-.893 59.068 12.605 81.258c13.497 22.191 41.768 30.305 64.98 18.65a2.286 2.286 0 112.042 4.084 54.415 54.415 0 01-24.381 5.806z"
        fill="#1B7"
      />
      <path
        d="M40.853 36.343a2.286 2.286 0 01-1.287-4.175c1.34-.91 2.758-1.7 4.236-2.362a2.286 2.286 0 111.867 4.167 23.62 23.62 0 00-3.536 1.974 2.286 2.286 0 01-1.28.396zM31.238 66.552a2.286 2.286 0 01-2.14-1.485 27.855 27.855 0 011.76-23.383 2.286 2.286 0 013.992 2.225 23.307 23.307 0 00-1.479 19.558A2.286 2.286 0 0132 66.407c-.244.092-.502.14-.762.145z"
        fill="#1B7"
      />
      <path
        d="M128.312 143.459a11.269 11.269 0 01-7.992-3.269l-29.265-29.264a2.286 2.286 0 010-3.238l12.755-12.755a2.286 2.286 0 011.615-.663 2.286 2.286 0 011.615.663l6.804 6.804a2.287 2.287 0 01-3.238 3.23l-5.181-5.18-9.524 9.523 27.65 27.65a6.735 6.735 0 009.523-9.524l-14.057-14.057a2.287 2.287 0 013.238-3.23l14.05 14.041a11.299 11.299 0 01-7.993 19.292v-.023z"
        fill="#285"
      />
      <path
        d="M107.878 111.528a2.286 2.286 0 01-1.615-3.901l5.135-5.136a2.287 2.287 0 013.238 3.23l-5.143 5.136c-.428.429-1.009.67-1.615.67z"
        fill="#285"
      />
    </g>
  </svg>
);
