import { useAsync, useAsyncCallback } from 'react-async-hook';
import { useTranslation } from '@creditas/i18n';
import { useToaster } from '@creditas/toaster';
import { useQueryParams, withDefault, NumberParam, StringParam } from 'use-query-params';
import React, { useState } from 'react';

import {
  getLoanBlockings,
  createLoanBlocking,
  removeLoanBlocking,
} from '../../../services/funding-eligibility/FundingEligibility';
import { LoanBlockingPageProvider } from '../../../contexts';
import { LoanBlockingsAddButton } from './components/loan-blockings-add-button';
import { LoanBlockingsAddModal } from './components/loan-blockings-add-modal';
import { LoanBlockingsRemoveValidationModal } from './components/loan-blockings-remove-validation-modal';
import { LoanBlockingsTable } from './components/loan-blockings-table';
import {
  FilterValues,
  LoanBlockingsFilters,
} from './components/loan-blockings-filters/LoanBlockingsFilters';

export const LoanBlockings: React.FC = () => {
  const { t } = useTranslation();
  const { push } = useToaster();
  const headerTitles = [
    t('loanBlockingsTable.header.contract'),
    t('loanBlockingsTable.header.reason'),
    t('loanBlockingsTable.header.action'),
  ];

  const defaultFilterParams = {
    creditCertificateNumber: undefined,
    reason: undefined,
  };

  const [query, setQuery] = useQueryParams({
    creditCertificateNumber: withDefault(StringParam, defaultFilterParams.creditCertificateNumber),
    reason: withDefault(StringParam, defaultFilterParams.reason),
    page: withDefault(NumberParam, 1),
    pageSize: withDefault(NumberParam, 10),
  });

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const isChangedStateFiltersQuery = Object.entries(defaultFilterParams).some(
    ([key, value]): boolean => {
      return (query as any)[key] !== value;
    },
  );

  const [
    showRemoveLoanBlockingValidationModal,
    setShowRemoveLoanBlockingValidationModal,
  ] = useState(false);
  const [showAddLoanBlockingModal, setShowAddLoanBlockingModal] = useState(false);

  const [loanBlockingRequest, setLoanBlockingRequest] = useState({
    creditCertificateNumber: '',
    reason: '',
  });

  const filterValues = {
    ...query,
  };

  const { result: loanBlockings, loading, execute } = useAsync(
    () =>
      getLoanBlockings({
        page: query.page,
        size: query.pageSize,
        creditCertificateNumber: query.creditCertificateNumber,
        reason: query.reason,
      }),
    [query],
    {
      setLoading: state => ({ ...state, loading: true }),
    },
  );

  const createLoanBlockingCallback = useAsyncCallback(createLoanBlocking, {
    onSuccess: () => {
      execute();
      push(t('loanBlocking.create.feedbackMessage.success'), { level: 'success' });
    },
    onError: e =>
      e.message === 'Request failed with status code 422'
        ? push(t('loanBlocking.create.feedbackMessage.alreadyRegistered'), { level: 'danger' })
        : push(t('loanBlocking.create.feedbackMessage.error'), { level: 'danger' }),
  });

  const removeLoanBlockingCallback = useAsyncCallback(removeLoanBlocking, {
    onSuccess: () => {
      execute();
    },
  });

  const onFiltersChange = (values: FilterValues): void => {
    setQuery({ ...values, page: 1, creditCertificateNumber: values.creditCertificateNumber });
  };

  const onReset = (): void => {
    setQuery({ pageSize: query.pageSize }, 'push');
  };

  return (
    <LoanBlockingPageProvider
      value={{
        setLoanBlockingRequest,
        setShowRemoveModal: setShowRemoveLoanBlockingValidationModal,
      }}
    >
      <LoanBlockingsFilters
        values={filterValues}
        onChange={onFiltersChange}
        onReset={onReset}
        resetButtonDisabled={!isChangedStateFiltersQuery}
        loading={false}
      />
      <LoanBlockingsAddButton
        label={t('loanBlocking.addNew')}
        setShowModal={setShowAddLoanBlockingModal}
      />
      <LoanBlockingsTable
        headers={headerTitles}
        data={loanBlockings?.data}
        loading={loading}
        page={query.page}
        pageSize={query.pageSize}
        setPage={(page: number): void => setQuery({ page })}
        setPageSize={(pageSize: number): void => setQuery({ pageSize, page: 1 })}
      />
      <LoanBlockingsAddModal
        showModal={showAddLoanBlockingModal}
        setShowModal={setShowAddLoanBlockingModal}
        createLoanBlocking={createLoanBlockingCallback.execute}
      />
      <LoanBlockingsRemoveValidationModal
        question={t('questions.removeValidation')}
        removeLoanBlocking={removeLoanBlockingCallback.execute}
        creditCertificateNumber={loanBlockingRequest.creditCertificateNumber}
        reason={loanBlockingRequest.reason}
        showRemoveModal={showRemoveLoanBlockingValidationModal}
        setShowRemoveModal={setShowRemoveLoanBlockingValidationModal}
      />
    </LoanBlockingPageProvider>
  );
};
