import React from 'react';

import { Action } from '@creditas/icons';
import { GridItem } from '@creditas/layout';
import { useTranslation } from '@creditas/i18n';
import { InputField } from '@creditas/form-elements';
import {
  LoanBlockingsFiltersTitle,
  gridFiltersTitle,
  gridReasonFilter,
  gridResetFilters,
  LoanBlockingsFilterSelect,
  gridContractFilter,
} from './LoanBlockingsFilters.style';
import LoadableContent from '../../../../../modules/LoadableContent';
import { getReasons } from '../../../../../services/funding-eligibility/FundingEligibility';
import { ActionLink } from '../../../components/PageTitle/action-link/ActionLink.style';

export type FilterValues = {
  creditCertificateNumber?: string;
  reason?: string;
};

const reasonFilterToOption = (value?: string): string => {
  return value || 'all';
};

const reasonFilterFromOption = (value: string): string | undefined => {
  return value === 'all' ? undefined : value;
};

export type FiltersProps = {
  values: FilterValues;
  resetButtonDisabled: boolean;
  onChange: (values: FilterValues) => void;
  onReset: () => void;
  loading?: boolean;
};

const reasons = getReasons();

const inputBackground = '#f8f8f8';

export const LoanBlockingsFilters: React.FC<FiltersProps> = ({
  values,
  resetButtonDisabled,
  loading,
  onChange,
  onReset,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <GridItem gridColumn={gridFiltersTitle.gridColumn} gridRow={gridFiltersTitle.gridRow}>
        <LoanBlockingsFiltersTitle variant="textParagraph">
          {t('filters.title')}
        </LoanBlockingsFiltersTitle>
      </GridItem>
      <GridItem gridColumn={gridContractFilter.gridColumn} gridRow={gridContractFilter.gridRow}>
        <LoadableContent loading={loading} fullWidth height={40} bottomSpacing={16}>
          <InputField
            data-testid="credit-certificate-number-filter"
            label={t('filters.creditCertificateNumber.title')}
            onChange={(item): void =>
              onChange({ ...values, creditCertificateNumber: item.target.value })
            }
            size="comfortable"
            value={values.creditCertificateNumber ? values.creditCertificateNumber : ''}
            noItemsText={t('filters.assignee.noItems')}
            inputBackground={inputBackground}
            width=""
          />
        </LoadableContent>
      </GridItem>
      <GridItem gridColumn={gridReasonFilter.gridColumn} gridRow={gridReasonFilter.gridRow}>
        <LoadableContent loading={loading} fullWidth height={40} bottomSpacing={16}>
          <LoanBlockingsFilterSelect
            data-testid="reason-filter"
            label={t('filters.reasons.title')}
            inputBackground={inputBackground}
            hideDefaultOption
            size="comfortable"
            width=""
            value={reasonFilterToOption(values.reason)}
            options={[{ text: t('filters.reasons.options.all'), value: 'all' }, ...reasons]}
            onChange={(event): void =>
              onChange({ ...values, reason: reasonFilterFromOption(event.target.value) })
            }
          />
        </LoadableContent>
      </GridItem>
      <GridItem gridColumn={gridResetFilters.gridColumn} gridRow={gridResetFilters.gridRow}>
        <LoadableContent loading={loading} bottomSpacing={32} height={24} width={200}>
          <ActionLink
            onClick={resetButtonDisabled ? undefined : onReset}
            disabled={resetButtonDisabled}
          >
            <Action.XCircle />
            {t('filters.cleanAll')}
          </ActionLink>
        </LoadableContent>
      </GridItem>
    </>
  );
};
