import React, { useCallback, useState } from 'react';

import { styled } from '@creditas/stylitas';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDropzone } from 'react-dropzone';

const FileContainer = styled.div`
  display: flex;
  padding-top: 1.25rem;
  padding-bottom: 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  padding: 0px 0px 10px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 0.5rem;
`;

type Props = {
  receivedFile: (file: File) => void;
};

export const UploadFileBox = ({ receivedFile }: Props) => {
  const [fileToUpload, setFileToUpload] = useState<File>();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFileToUpload(acceptedFiles[0]);
      const reader = new FileReader();
      receivedFile(acceptedFiles[0]);
    },
    [receivedFile],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv'],
    },
  });

  return (
    <div
      style={{
        border: '1px dashed #D1D5DB',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        textAlign: 'center',
        height: '150px',
        position: 'relative',
        padding: '0px 20px',
        borderRadius: '16px',
        backgroundColor: '#edf0ef',
        fontFamily: 'Helvetica Now Display',
        fontWeight: '400',
        // marginTop: '2rem',
        color: '#596b64',
      }}
      {...getRootProps()}
    >
      <input data-testid="fileInput" {...getInputProps()} />
      <FileContainer>
        <TextContainer>
          {isDragActive && !fileToUpload && (
            <>
              <svg
                style={{
                  height: '2rem',
                  color: '#596b64',
                }}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 12V1m0 0L4 5m4-4 4 4m3 5v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                />
              </svg>
              <div style={{ lineHeight: '25px' }}>
                <span className="font-semibold">Arraste aqui seu arquivo</span>
                <p>Apenas formato .csv</p>
              </div>
            </>
          )}

          {!fileToUpload && !isDragActive && (
            <>
              <svg
                style={{
                  height: '2rem',
                  color: '#596b64',
                }}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 12V1m0 0L4 5m4-4 4 4m3 5v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                />
              </svg>
              <div style={{ lineHeight: '25px' }}>
                <span className="font-semibold">Clique para fazer upload</span> ou arraste aqui
                <p>Apenas formato .csv</p>
              </div>
            </>
          )}
        </TextContainer>
        {fileToUpload && !isDragActive && (
          <>
            <svg
              style={{ height: '1.5rem', color: '#596b64' }}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 18 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 8V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 7.828 1h8.239A.969.969 0 0 1 17 2v16a.969.969 0 0 1-.933 1H3.933A.97.97 0 0 1 3 18v-2M8 1v4a1 1 0 0 1-1 1H3m-2 6h10M9.061 9.232 11.828 12l-2.767 2.768"
              />
            </svg>
            <p style={{ lineHeight: '3rem' }}>{fileToUpload?.name}</p>
          </>
        )}
      </FileContainer>
    </div>
  );
};
