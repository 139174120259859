import React from 'react';
import { Typography } from '@creditas/typography';
import { GridItem } from '@creditas/layout';
import { gridPageTitle } from './PageTitle.style';

interface PageTitleProps {
  title: string;
}

export const PageTitle: React.FC<PageTitleProps> = ({ title }) => {
  return (
    <GridItem gridColumn={gridPageTitle.gridColumn} gridRow={gridPageTitle.gridRow}>
      <Typography variant="h4">{title}</Typography>
    </GridItem>
  );
};
