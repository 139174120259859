import React, { ChangeEvent, RefObject, useRef, useState } from 'react';
import { GridItem } from '@creditas/layout';
import { useTranslation } from '@creditas/i18n';
import { InputSelect } from '@creditas/form-elements';
import { useToaster } from '@creditas/toaster';
import { Modal } from '../../../../../modules/Modal';
import {
  AddCompanyBlockingForm,
  gridContainerOptions,
  StyledButton,
  ModalTitle,
  gridItemModalTitle,
  gridCancelButton,
  gridConfirmButton,
  gridUploadBox,
  gridBlockingCreditors,
} from './CompanyBlockingsAddModal.style';
import { UploadFileBox } from '../upload-file-box/UploadFileBox';

interface CompanyBlockingsAddButtonProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  createCompanyBlocking: ({
    fileRef,
    creditorPlatformId,
  }: {
    fileRef: RefObject<File | null>;
    creditorPlatformId: string;
  }) => Promise<string | void>;
}

export const CompanyBlockingsAddModal: React.FC<CompanyBlockingsAddButtonProps> = ({
  showModal,
  setShowModal,
  createCompanyBlocking,
}) => {
  const { t } = useTranslation();
  const { push } = useToaster();
  const fileRef = useRef<File | null>(null);
  const [creditorPlatformId, setCreditorPlatformId] = useState('');
  const getOptions = [
    {
      value: 'FUN-36EB84AF-996A-4D07-B78B-4BCAD280539A',
      text: 'FIDC Angá',
    },
    {
      value: 'FUN-560C0FCC-5E31-4C71-9736-750787FE2C09',
      text: 'Andbank',
    },
  ];

  return (
    <Modal canShow={showModal} hide={(): void => setShowModal(false)} width="700px">
      <AddCompanyBlockingForm options={gridContainerOptions}>
        <GridItem gridColumn={gridItemModalTitle.gridColumn} gridRow={gridItemModalTitle.gridRow}>
          <ModalTitle>{t('companyBlocking.addNew')}</ModalTitle>
        </GridItem>
        <GridItem
          gridColumn={gridBlockingCreditors.gridColumn}
          gridRow={gridBlockingCreditors.gridRow}
        >
          <InputSelect
            label={t('companyBlocking.creditor')}
            size="default"
            width="100%"
            options={getOptions}
            onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
              setCreditorPlatformId(e.target.value);
            }}
          />
        </GridItem>
        <GridItem gridColumn={gridUploadBox.gridColumn} gridRow={gridUploadBox.gridRow}>
          <UploadFileBox
            receivedFile={(file): void => {
              // if (!fileRef.current) {
              //   push(t('loanBlocking.create.feedbackMessage.fileEmpty'), { level: 'danger' });
              //   return;
              // }
              fileRef.current = file;
            }}
            data-testid="fileInput"
          />
        </GridItem>
        <GridItem gridColumn={gridCancelButton.gridColumn} gridRow={gridCancelButton.gridRow}>
          <StyledButton
            data-testid="deny-action"
            minWidth="127px"
            variant="secondary"
            onClick={(): void => setShowModal(false)}
          >
            {t('cancel')}
          </StyledButton>
        </GridItem>
        <GridItem gridColumn={gridConfirmButton.gridColumn} gridRow={gridConfirmButton.gridRow}>
          <StyledButton
            minWidth="127px"
            data-testid="accept-action"
            onClick={(): void => {
              createCompanyBlocking({
                fileRef,
                creditorPlatformId,
              });
              setShowModal(false);
            }}
          >
            {t('add')}
          </StyledButton>
        </GridItem>
      </AddCompanyBlockingForm>
    </Modal>
  );
};
